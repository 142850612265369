// @ts-strict-ignore
import React from "react"

import moment from "moment"

import ButtonLink from "@kiwicom/orbit-components/lib/ButtonLink"
import Popover from "@kiwicom/orbit-components/lib/Popover"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Table, {
  TableBody,
  TableCell,
  TableRow,
} from "@kiwicom/orbit-components/lib/Table"
import Text from "@kiwicom/orbit-components/lib/Text"
import Tooltip from "@kiwicom/orbit-components/lib/Tooltip"
import Calendar from "@kiwicom/orbit-components/lib/icons/Calendar"
import CheckCircle from "@kiwicom/orbit-components/lib/icons/CheckCircle"
import Money from "@kiwicom/orbit-components/lib/icons/Money"
import Timer from "@kiwicom/orbit-components/lib/icons/Timer"

import { StopCharging } from "components/charger-management/connectors/stop-charging"
import { createUrlBuilder } from "components/charger-management/utils"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"

import { formatAsDuration, formatUTCAsExactDateTime } from "utils/date-utils"
import { penniesInToPoundsStr } from "utils/string-utils"

import {
  ChargingSession,
  Fleet,
  ReservedConnector,
  VehicleChargingPort,
} from "./interfaces"
import {
  chargingPortNameWithPosition,
  chargingSessionPower,
  linkToMap,
} from "./utils"

type ChargeMetricType = {
  icon: React.ReactNode
  children: React.ReactNode
}

export const ChargeMetric = ({ icon, children }: ChargeMetricType) => (
  <Stack direction="row" spacing="XSmall" shrink inline grow={false}>
    {icon}
    <Text>{children}</Text>
  </Stack>
)

type ChargeReservationType = {
  connectorReservation: ReservedConnector
  vehicleChargingPorts?: Array<VehicleChargingPort>
  fleets?: Array<Fleet>
  type: "current" | "upcoming" | "historical"
  date: string
  chargingSessions?: ChargingSession[]
}

function endTime(chargingSession: ChargingSession) {
  return chargingSession.finishedAt || chargingSession.cancelledAt
}

export function isEnded(chargingSession: ChargingSession): boolean {
  return endTime(chargingSession) !== null
}

function isNotEnded(chargingSession: ChargingSession): boolean {
  return !isEnded(chargingSession)
}

function chargingSessionCostMetric(chargingSession: ChargingSession) {
  const power = chargingSessionPower(chargingSession)
  if (!power) {
    return null
  }
  return (
    <ChargeMetric key="cost" icon={<Money color="success" />}>
      {penniesInToPoundsStr((power / 1000) * 19)}{" "}
    </ChargeMetric>
  )
}

export function chargingSessionEnergyMetric(chargingSession: ChargingSession) {
  const power = chargingSessionPower(chargingSession)
  if (!power) {
    return null
  }
  const powerInKwH = power / 1000
  return (
    <ChargeMetric key="energy" icon={<CheckCircle color="success" />}>
      {Math.round(powerInKwH)}
      {isEnded(chargingSession) ? " kWh" : " kW"}
    </ChargeMetric>
  )
}

export function chargingSessionStateOfChargeMetric(
  chargingSession: ChargingSession
) {
  const stateOfCharge = chargingSession.measurements.soC?.value
  if (!stateOfCharge) {
    return null
  }
  return (
    <ChargeMetric key="state-of-charge" icon={<CheckCircle color="success" />}>
      {Math.round(stateOfCharge)}%
      {isEnded(chargingSession) ? " final charge" : " current charge"}
    </ChargeMetric>
  )
}

export function chargingSessionDuration(chargingSession: ChargingSession) {
  return (
    <ChargeMetric key="duration" icon={<Timer color="success" />}>
      {formatAsDuration(chargingSession.startedAt, endTime(chargingSession))}
    </ChargeMetric>
  )
}

export function chargingSessionStart(chargingSession: ChargingSession) {
  return (
    <Tooltip
      removeUnderlinedText
      content={
        <Stack direction="column" spacing="small" shrink>
          <Text>
            Start: {formatUTCAsExactDateTime(chargingSession.startedAt)}
          </Text>
          {endTime(chargingSession) ? (
            <Text>
              End: {formatUTCAsExactDateTime(endTime(chargingSession))}
            </Text>
          ) : null}
        </Stack>
      }
    >
      <ChargeMetric key="start-time" icon={<Calendar color="success" />}>
        {moment(chargingSession.startedAt).format("HH:mm")}
      </ChargeMetric>
    </Tooltip>
  )
}

function stopCharging(
  chargingSession: ChargingSession,
  chargePointIdentifier: string
) {
  return (
    <Popover
      content={
        <React.Fragment>
          <Text spaceAfter="normal">
            Are you sure? Click below to stop charging
          </Text>
          <StopCharging
            transactionID={chargingSession.chargingSessionId}
            urlBuilder={createUrlBuilder(chargePointIdentifier)}
          />
        </React.Fragment>
      }
    >
      <ButtonLink type="critical">Stop Charging</ButtonLink>
    </Popover>
  )
}

function displayVehicleChargingPorts(
  vehicleChargingPorts?: Array<VehicleChargingPort>
) {
  if (!vehicleChargingPorts) {
    return null
  }
  return (
    <Stack direction="row" spacing="small" inline>
      <Text>Authorised Vehicles:</Text>
      <Text>
        {vehicleChargingPorts.map(chargingPortNameWithPosition).join(", ")}
      </Text>
    </Stack>
  )
}

function displayFleets(fleets?: Array<Fleet>) {
  if (!fleets) {
    return null
  }
  return (
    <Stack direction="row" spacing="small" inline>
      <Text>Authorised Fleets:</Text>
      <Text>
        {fleets
          .map((fleet) => {
            return fleet.name
          })
          .join(", ")}
      </Text>
    </Stack>
  )
}

function displayCurrentChargeMetrics(
  chargingSession: ChargingSession,
  chargePointIdentifier: string
) {
  return (
    <>
      <TableCell>{chargingSessionStart(chargingSession)}</TableCell>
      <TableCell>{chargingSessionEnergyMetric(chargingSession)}</TableCell>
      <TableCell>
        {chargingSessionStateOfChargeMetric(chargingSession)}
      </TableCell>
      <TableCell>{chargingSessionCostMetric(chargingSession)}</TableCell>
      <TableCell>
        {stopCharging(chargingSession, chargePointIdentifier)}
      </TableCell>
    </>
  )
}

function displayFinishedChargeMetrics(chargingSession: ChargingSession) {
  return (
    <>
      <TableCell>{chargingSessionStart(chargingSession)}</TableCell>
      <TableCell>
        {chargingSessionStateOfChargeMetric(chargingSession)}
      </TableCell>
      <TableCell>{chargingSessionEnergyMetric(chargingSession)}</TableCell>
      <TableCell>{chargingSessionCostMetric(chargingSession)}</TableCell>
      <TableCell>{chargingSessionDuration(chargingSession)}</TableCell>
    </>
  )
}

function buildChargingSessionDisplay(
  reservationType: "current" | "upcoming" | "historical",
  chargingSessions: Array<ChargingSession>,
  chargePointIdentifer: string,
  displayType: "current" | "finished"
) {
  const filterFunc = displayType === "finished" ? isEnded : isNotEnded
  const filteredSessions = chargingSessions
    .filter(filterFunc)
    .sort((left, right) => {
      return endTime(right) > endTime(left) ? 1 : -1
    })

  if (displayType === "finished" && filteredSessions.length === 0) {
    // Don't display anything in the absence of finished sessions
    return null
  }

  if (displayType === "current" && reservationType !== "current") {
    /// Don't display current sessions unless the reservation is current
    return null
  }

  let details = null
  if (filteredSessions.length !== 0) {
    details = filteredSessions.map((session: ChargingSession) => {
      return (
        <TableRow key={session.chargingSessionId}>
          <TableCell>
            <Text>
              {session.vehicleChargingPort?.licensePlate || "Unknown Vehicle"}
            </Text>
          </TableCell>
          {displayType === "current"
            ? displayCurrentChargeMetrics(session, chargePointIdentifer)
            : displayFinishedChargeMetrics(session)}
        </TableRow>
      )
    })
  }
  return (
    <EmberCardSection>
      <Stack direction="column" spacing="small">
        <Text type="secondary" size="small" weight="bold" uppercase>
          {displayType === "current"
            ? "Currently Charging"
            : "Finished Charging"}
        </Text>
        {displayType == "current" && !details ? (
          <Text>No vehicles are currently charging</Text>
        ) : (
          <Table striped={false}>
            <TableBody>{details}</TableBody>
          </Table>
        )}
      </Stack>
    </EmberCardSection>
  )
}

const ChargeReservation = ({
  connectorReservation,
  type,
  date,
  vehicleChargingPorts,
  fleets,
  chargingSessions,
}: ChargeReservationType) => {
  return (
    <EmberCard key={connectorReservation.reservedConnectorId}>
      <EmberCardSection>
        <Stack
          direction="column"
          spacing="XXSmall"
          key={connectorReservation.reservedConnectorId}
        >
          <Stack direction="row" spacing="XSmall" shrink>
            <Text size="large" weight="bold">
              {connectorReservation.connector.chargePointName}
            </Text>
            {linkToMap(
              connectorReservation.connector.location.latitude,
              connectorReservation.connector.location.longitude
            )}
          </Stack>
          <Stack direction="row" spacing="small" inline>
            <Text>Cable {connectorReservation.connector.localConnectorId}</Text>
            <Text>•</Text>
            <Text>up to {connectorReservation.maxPower}kW</Text>
            <Text>•</Text>
            <Text>{date}</Text>
          </Stack>
          {displayVehicleChargingPorts(vehicleChargingPorts)}
          {displayFleets(fleets)}
        </Stack>
      </EmberCardSection>
      {buildChargingSessionDisplay(
        type,
        chargingSessions,
        connectorReservation.connector.chargePointIdentifier,
        "current"
      )}
      {buildChargingSessionDisplay(
        type,
        chargingSessions,
        connectorReservation.connector.chargePointIdentifier,
        "finished"
      )}
    </EmberCard>
  )
}

export default ChargeReservation
