// @ts-strict-ignore
import React from "react"

import TextLink from "@kiwicom/orbit-components/lib/TextLink"

import { urlBuilderType } from "../utils"
import { ChargerButton } from "./button"

interface StartChargingProps {
  connectorID: number
  urlBuilder: urlBuilderType
}

/**
 * StartCharging sends a request to start charging on the supplied connectorID when clicked
 *
 * @param props
 * @returns {React.ReactNode}
 */
export function StartCharging(props: StartChargingProps): JSX.Element {
  return (
    <ChargerButton
      componentType={TextLink}
      path="start_transaction"
      buttonText="Start Charging"
      urlBuilder={props.urlBuilder}
      payload={{
        connector_id: props.connectorID,
        id_tag: "SPECIAL_ID_TAG", // TODO: Need to send a real one through so we know which vehicle it is
      }}
      expectedResponses={{
        Accepted: {
          wasSuccessful: true,
          message:
            "Success! Note, starting charging when the cable isn't plugged into a vehicle will still result this message saying success. But we obviously can't charge when a vehicle isn't plugged in.",
        },
        Rejected: {
          wasSuccessful: false,
          message: "The charger rejected your command to start charging",
        },
      }}
      tooltip="Start a charging session at this cable. Make sure the vehicle is already plugged in before attempting to start charging."
    />
  )
}
