import React from "react"

import { urlBuilderType } from "../utils"
import { ChargerButton } from "./button"

interface MessageTriggerProps {
  buttonText: string
  messageType:
    | "StatusNotification"
    | "BootNotification"
    | "Heartbeat"
    | "MeterValues"
  urlBuilder: urlBuilderType
  connectorID: number
  tooltip: string
}

/**
 * MessageTrigger encapuslates logic for the different requests to the trigger_message/ endpoint
 * Each triggered message type corresponds to a different kind of information for the charger to send.
 *
 * For example, a StatusNotification messageType requests the charger to send an
 * updated status for the cable identified by connectorID
 *
 * @param props
 * @returns JSX for a MessageTrigger button
 */
export function MessageTrigger(props: MessageTriggerProps): JSX.Element {
  return (
    <ChargerButton
      path="trigger_message"
      buttonText={props.buttonText}
      urlBuilder={props.urlBuilder}
      tooltip={props.tooltip}
      payload={{
        connector_id: props.connectorID,
        requested_message: props.messageType,
      }}
      expectedResponses={{
        Accepted: {
          wasSuccessful: true,
          message: "Success!",
        },
        Rejected: {
          wasSuccessful: false,
          message: `The charger rejected your ${props.messageType} request`,
        },
      }}
    />
  )
}
