import styled, { css } from "styled-components"

import media from "@kiwicom/orbit-components/lib/utils/mediaQuery"

interface DetailsWrapperType {
  fullScreen: boolean
  sidebarPadding?: boolean
}

export const DetailsWrapper = styled.div<DetailsWrapperType>`
  width: 100%;
  ${(props) =>
    props.fullScreen != true &&
    css`
      padding: 0px ${(props) => props.theme.orbit.spaceMedium};
      ${media.tablet(css`
        padding: 0px ${(props) => props.theme.orbit.spaceLarge};
      `)}
      > :first-child {
        margin-top: 20px;
        ${media.tablet(
          css`
            margin-top: 30px;
          `
        )}
      }
      > :last-child {
        margin-bottom: 20px;
        ${media.tablet(
          css`
            margin-bottom: 30px;
          `
        )}
      }
    `}
  ${(props) =>
    props.sidebarPadding == true &&
    css`
      padding: 0px 20px;
      ${media.tablet(css`
        padding: 0px 20px;
      `)}
      > :first-child {
        margin-top: 20px;
        ${media.tablet(
          css`
            margin-top: 20px;
          `
        )}
      }
    `}
`

interface DetailsInnerType {
  fullScreen: boolean
}

export const DetailsInner = styled.div<DetailsInnerType>`
  ${(props) =>
    props.fullScreen != true &&
    css`
      max-width: 1200px;
      margin: 0 auto;
      margin-bottom: 100px !important;
    `}
`
