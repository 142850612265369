// @ts-strict-ignore
import React from "react"

import Button from "@kiwicom/orbit-components/lib/Button"
import Popover from "@kiwicom/orbit-components/lib/Popover"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import { ChargerButton } from "./connectors/button"
import { urlBuilderType } from "./utils"

interface RestartProps {
  urlBuilder: urlBuilderType
  restartType: "Hard" | "Soft"
  buttonText: string
  buttonType: "critical" | "criticalSubtle"
}

interface SpecificRestartProps {
  urlBuilder: urlBuilderType
}

/**
 * RestartButton makes it possible to do either a soft of hard restart.
 * The type of restart is parameterised by the restartType prop.
 *
 * @param props
 * @returns {JSX.Element}
 */
function RestartButton(props: RestartProps): JSX.Element {
  return (
    <ChargerButton
      path="reset"
      componentType={Button}
      extraComponentProps={{
        type: props.buttonType,
      }}
      buttonText={props.buttonText}
      urlBuilder={props.urlBuilder}
      payload={{
        reset_type: props.restartType,
      }}
      tooltip={null}
      expectedResponses={{
        Accepted: {
          wasSuccessful: true,
          message: "Success!",
        },
        Rejected: {
          wasSuccessful: false,
          message: "The charger rejected your request to restart",
        },
      }}
    />
  )
}

/**
 * Restart exposes the ability to do either a soft or a hard restart, as well
 * as guidance about which type of restart to do.
 *
 * @param props
 * @returns {JSX.Element}
 */
export function Restart(props: SpecificRestartProps): JSX.Element {
  return (
    <div>
      <Popover
        width="400px"
        content={
          <Stack>
            <Text>Restarting the charger takes a few minutes.</Text>
            <Text>
              Any current charging sessions will end. Charging <b>should</b>{" "}
              begin again after a restart.
            </Text>
            <Text>
              A <b>Soft Restart</b> restarts the software in the charger. Use
              this as your default approach. For example, to apply configuration
              changes.
            </Text>
            <Text>
              A <b>Hard Restart</b> restarts the hardware of the charger. Use
              this as a last resort. The charger might not send some messages.
              It might also end an ongoing charging session in the expected way.
            </Text>
            <Stack direction="row">
              <RestartButton
                urlBuilder={props.urlBuilder}
                restartType="Soft"
                buttonText="Soft Restart"
                buttonType="criticalSubtle"
              />
              <RestartButton
                urlBuilder={props.urlBuilder}
                restartType="Hard"
                buttonText="Hard Restart"
                buttonType="critical"
              />
            </Stack>
          </Stack>
        }
      >
        <Button type="criticalSubtle">Restart</Button>
      </Popover>
    </div>
  )
}
