// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { Loading } from "@kiwicom/orbit-components"
import Heading from "@kiwicom/orbit-components/lib/Heading"
import Inline from "@kiwicom/orbit-components/lib/Inline"
import Separator from "@kiwicom/orbit-components/lib/Separator"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import Tile from "@kiwicom/orbit-components/lib/Tile"

import { linkToMap } from "components/charging/utils"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"

import { fetchFromAPIBase } from "utils/fetch-utils"
import { useInterval } from "utils/use-interval"

import { ChargerConfiguration } from "./configuration"
import {
  ConnectorAdmin,
  ExtendedChargingSession,
} from "./connectors/connector-admin"
import { MessageTrigger } from "./connectors/message-trigger"
import { RawFeed } from "./raw-feed"
import { Restart } from "./restart"
import { createUrlBuilder, urlBuilderType } from "./utils"

interface ChargerAdminProps {
  selectedChargerID: string
}

/**
 * ChargerAdmin is the parent for all administrative functionality for a charger.
 * may include getting updates about the charger state, seeing configuration, restarting the charger, etc.
 */
export const ChargerAdmin = ({ selectedChargerID }: ChargerAdminProps) => {
  const [chargePoint, setChargePoint] = useState(null)
  const [reservations, setReservations] = useState(null)
  const [chargingSessions, setChargingSessions] =
    useState<ExtendedChargingSession[]>(null)
  const [fullRefreshCount, setFullRefreshCount] = useState(1)

  const [isRefreshingChargePoint, setIsRefreshingChargePoint] = useState(true)
  const [isRefreshingChargingSessions, setRefreshingChargingSessions] =
    useState(false)
  const [isRefreshingReservations, setRefreshingReservations] = useState(false)

  useEffect(() => {
    fullRefresh()
  }, [selectedChargerID, fullRefreshCount])

  function fullRefresh() {
    setIsRefreshingChargePoint(true)
    if (selectedChargerID !== chargePoint?.identifier) {
      // New charger, reset the charging sessions and reservations
      setReservations(null)
      setChargingSessions(null)
    }
    updateChargePoint()
    updateChargingSessions()
    updateReservations()
    setIsRefreshingChargePoint(false)
  }

  function urlBuilder(): urlBuilderType {
    return createUrlBuilder(chargePoint?.identifier)
  }

  useInterval(() => {
    setFullRefreshCount(fullRefreshCount + 1) // Trigger full refresh
  }, 5000)

  function updateChargePoint() {
    fetchFromAPIBase({
      path: `/v1/charging/charge_points/?include_measurements=true&identifiers=${selectedChargerID}`,
      method: "GET",
    }).subscribe((response) => {
      if (response && !response.error) {
        setChargePoint(response[0])
      }
    })
  }

  function updateChargingSessions() {
    setRefreshingChargingSessions(true)
    fetchFromAPIBase({
      path: `/v1/charging/charging_sessions/?charge_point_identifiers=${selectedChargerID}`,
      method: "GET",
    }).subscribe((response) => {
      if (response && !response.error) {
        setChargingSessions(response)
      }
    })
    setRefreshingChargingSessions(false)
  }

  function updateReservations() {
    setRefreshingReservations(true)
    const now = new Date()
    fetchFromAPIBase({
      path: `/v1/charging/reservations/?window_start=${now.toISOString()}&page_size=5`,
    }).subscribe((response) => {
      if (response && !response.error) {
        setReservations(response)
      }
    })
    setRefreshingReservations(false)
  }

  const connectorAdminPages = chargePoint?.connectors.map((connector) => {
    return (
      <div key={connector.localConnectorId.toString()}>
        <ConnectorAdmin
          urlBuilder={urlBuilder()}
          connectorID={connector.localConnectorId}
          chargePoint={chargePoint}
          reservations={reservations || []}
          chargingSessions={chargingSessions || []}
          updateChargePointHook={updateChargePoint}
          isRefreshingChargingSessions={isRefreshingChargingSessions}
          isRefreshingReservations={isRefreshingReservations}
        />
        <Separator />
      </div>
    )
  })
  return isRefreshingChargePoint ||
    chargePoint?.identifier != selectedChargerID ? (
    <Loading type="pageLoader" text="Loading charger details..." />
  ) : (
    <Stack spacing="large">
      <Inline>
        <Stack direction="row" align="center" justify="between">
          <Heading>{chargePoint.name}</Heading>
          <Stack direction="row" align="end" justify="end" inline>
            {linkToMap(
              chargePoint.location.latitude,
              chargePoint.location.longitude
            )}
          </Stack>
        </Stack>
      </Inline>

      <Stack>
        <Text weight="bold" uppercase>
          Actions
        </Text>
        <EmberCard>
          <EmberCardSection>
            <Stack
              direction="column"
              largeMobile={{ direction: "row" }}
              justify="between"
            >
              <Stack
                direction="column"
                largeMobile={{ direction: "row" }}
                shrink
              >
                <MessageTrigger
                  messageType="StatusNotification"
                  urlBuilder={urlBuilder()}
                  connectorID={0}
                  buttonText="Update Status"
                  tooltip="Ask the charger to send the status of the charger as a whole."
                />
                <MessageTrigger
                  messageType="BootNotification"
                  urlBuilder={urlBuilder()}
                  connectorID={0}
                  buttonText="Get Startup Message"
                  tooltip="Ask the charger to send its startup message. contains information like the firmware version it is running, the serial number and charger model."
                />
                <MessageTrigger
                  messageType="Heartbeat"
                  urlBuilder={urlBuilder()}
                  connectorID={0}
                  buttonText="Request Heartbeat"
                  tooltip="Ask the charger to send a heartbeat. synchronises time on the charger with our backend."
                />
              </Stack>
              <Restart urlBuilder={urlBuilder()} />
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>

      {connectorAdminPages}
      <ChargerConfiguration urlBuilder={urlBuilder()} />

      <Separator />

      <Tile title="Live Charger Logs" expandable>
        <RawFeed chargePointID={selectedChargerID} maxRecords={200} />
      </Tile>
    </Stack>
  )
}
