// @ts-strict-ignore
import React from "react"

import Loading from "@kiwicom/orbit-components/lib/Loading"
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@kiwicom/orbit-components/lib/Table"
import Tile from "@kiwicom/orbit-components/lib/Tile"

import { fetchWithAuth } from "utils/fetch-utils"

import { urlBuilderType } from "./utils"

interface ConfigurationKey {
  key: string
  value: string
  readonly: boolean
}

interface ChargerConfigurationProps {
  urlBuilder: urlBuilderType
}

interface ChargerConfigurationState {
  configuration?: Array<ConfigurationKey>
}

/**
 * ChargerConfiguration will request the current (entire) configuration of the charger and display it.
 */
export class ChargerConfiguration extends React.Component<
  ChargerConfigurationProps,
  ChargerConfigurationState
> {
  constructor(props: ChargerConfigurationProps) {
    super(props)
    this.state = {
      configuration: null,
    }
    this.request = this.request.bind(this)
  }

  request() {
    if (!this.state.configuration) {
      fetchWithAuth(this.props.urlBuilder("configuration"), "GET").subscribe(
        (response) => {
          if (response && !response.error) {
            this.setState({
              configuration: response.configurationKey,
            })
          }
        }
      )
    }
  }

  render(): JSX.Element {
    const keys = this.state.configuration?.map(
      (configuration_key: ConfigurationKey) => {
        return (
          <TableRow>
            <TableCell>{configuration_key.key}</TableCell>
            <TableCell>
              <div
                style={{
                  maxWidth: "300px",
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                }}
              >
                {configuration_key.value}
              </div>
            </TableCell>
            <TableCell>{configuration_key.readonly ? "No" : "Yes"}</TableCell>
          </TableRow>
        )
      }
    )

    const configurationTable = (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Configuration Key</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Editable?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{keys}</TableBody>
      </Table>
    )

    const content = this.state.configuration ? (
      configurationTable
    ) : (
      <Loading
        type="pageLoader"
        text="Hold tight! Getting the latest info from the charger"
      />
    )

    return (
      <Tile
        title="Current configuration"
        expandable={true}
        onClick={this.request}
      >
        {content}
      </Tile>
    )
  }
}
