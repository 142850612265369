// @ts-strict-ignore
import React from "react"

import TextLink from "@kiwicom/orbit-components/lib/TextLink"

import { urlBuilderType } from "../utils"
import { ChargerButton } from "./button"

interface UnlockProps {
  connectorID: number
  urlBuilder: urlBuilderType
}

/**
 * Unlock will request that the charger unlocks the cable identified by connectorID
 *
 * @param props
 * @returns {JSX.Element}
 */
export function Unlock(props: UnlockProps): JSX.Element {
  return (
    <ChargerButton
      componentType={TextLink}
      path="unlock"
      buttonText="Unlock the cable"
      urlBuilder={props.urlBuilder}
      payload={{
        connector_id: props.connectorID,
      }}
      expectedResponses={{
        Accepted: {
          wasSuccessful: true,
          message: "Success!",
        },
        Rejected: {
          wasSuccessful: false,
          message: "The charger rejected your request :(",
        },
        NotSupported: {
          wasSuccessful: false,
          message:
            "Unlocking isn't supported right now. The cable is probably already unlocked.",
        },
      }}
      tooltip="Usually the cable will unlock itself after a charging session. This button exists in case it hasn't for some reason and you want to force the cable to unlock."
    />
  )
}
