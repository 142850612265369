import React from "react"

import ButtonLink from "@kiwicom/orbit-components/lib/ButtonLink"

import { urlBuilderType } from "../utils"
import { ChargerButton } from "./button"

interface StopChargingProps {
  transactionID: number
  urlBuilder: urlBuilderType
}

/**
 * StopCharging will end the transaction identified by transactionID.
 *
 * @param props
 * @returns {JSX.Element}
 */
export function StopCharging(props: StopChargingProps): JSX.Element {
  return (
    <ChargerButton
      path="stop_transaction"
      buttonText="Stop Charging"
      componentType={ButtonLink}
      extraComponentProps={{
        type: "critical",
      }}
      urlBuilder={props.urlBuilder}
      payload={{
        transaction_id: props.transactionID,
      }}
      expectedResponses={{
        Accepted: {
          wasSuccessful: true,
          message: "Success!",
        },
        Rejected: {
          wasSuccessful: false,
          message: "The charger rejected your command to stop charging",
        },
      }}
      tooltip="Stop the ongoing charging session. Be careful, you won't be able to start charging again until someone physically disconnects and re-connects the charging cable."
    />
  )
}
