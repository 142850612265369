// @ts-strict-ignore
import React from "react"

import List, { ListItem } from "@kiwicom/orbit-components/lib/List"

import getJwtToken from "utils/fetch-utils"

interface RawFeedState {
  logs: Array<string>
}

interface RawFeedProps {
  chargePointID: string
  maxRecords: number
}

/**
 * RawFeed will display a direct feed of OCPP messages being sent to and from
 * the charger.
 */
export class RawFeed extends React.Component<RawFeedProps, RawFeedState> {
  websocket: WebSocket
  feedIsActive: boolean

  constructor(props: RawFeedProps) {
    super(props)

    this.state = { logs: [] }
    this.feedIsActive = false

    this.addNewLog = this.addNewLog.bind(this)
    this.processNewEvent = this.processNewEvent.bind(this)
    this.connect = this.connect.bind(this)
    this.reconnect = this.reconnect.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  addNewLog(newRow: string) {
    if (this.feedIsActive) {
      this.setState(function (previousState: RawFeedState): RawFeedState {
        const newState: RawFeedState = { logs: [newRow, ...previousState.logs] }
        if (newState.logs.length > this.props.maxRecords) {
          // We have more than the maximum number of logs in the state. Remove one
          newState.logs.pop()
        }
        return newState
      })
    }
  }

  processNewEvent(event: MessageEvent) {
    this.addNewLog(event.data)
  }

  reconnect() {
    // Reconnect to the raw feed, if the feed is active
    if (this.feedIsActive) {
      getJwtToken().then(this.connect)
    }
  }

  handleClose(closeEvent: CloseEvent) {
    // When the connection closes, reconnect
    switch (closeEvent.code) {
      case 4000: {
        this.addNewLog(
          "Connecting to the feed was not successful as the internet connection is too slow."
        )
        this.feedIsActive = false
        break
      }
      case 4001: {
        this.addNewLog("You are not authorised to see the charger feed")
        this.feedIsActive = false
        break
      }
      case 1000: {
        this.addNewLog(
          "The connection closed, normally. Hold your horses, it might be back in a bit."
        )
        break
      }
      default: {
        this.addNewLog(`Connection closed with code ${closeEvent.code}`)
      }
    }
    this.reconnect()
  }

  connect(jwtToken: string) {
    if (this.feedIsActive) {
      this.addNewLog("Opening connection to the charger")
      this.websocket = new WebSocket(
        `wss://api.ember.to/beta/charge/charge_point_feed/${this.props.chargePointID}`
      )
      this.websocket.onmessage = this.processNewEvent
      this.websocket.onclose = this.handleClose
      this.websocket.onopen = () => this.websocket.send(jwtToken) // The first message we send must be a valid token, to authorise us
    }
  }

  componentDidMount() {
    this.feedIsActive = true
    this.reconnect()
  }

  componentWillUnmount() {
    this.feedIsActive = false
    this.websocket?.close(1000)
  }

  render(): JSX.Element {
    const listItems = this.state.logs.map((logEntry: string) => {
      return <ListItem key={logEntry}>{logEntry}</ListItem>
    })
    return <List>{listItems}</List>
  }
}
