// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import styled from "styled-components"

import { Alert, Loading } from "@kiwicom/orbit-components"
import Heading from "@kiwicom/orbit-components/lib/Heading"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import { ChargerAdmin } from "components/charger-management/charger-admin"
import {
  Column,
  ColumnContext,
  ListTile,
  MultiColumnScrollSection,
  MultiColumnWrapper,
} from "components/generic/multi-column-layout"
import { AdminLayout } from "components/layout-custom"

import { fetchFromAPIBase } from "utils/fetch-utils"
import { sortBy } from "utils/struct-utils"

const ChargerInner = styled.div`
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
`

/**
 * ChargersPage contains administrative functionality for our chargers.
 *
 * It has a column on the left to select chargers and will display the
 * selected charger's information and actions in the right hand column
 */
export const Page = () => {
  return (
    <AdminLayout title="Chargers" padded={false}>
      <MultiColumnWrapper>
        <ChargerAdminPage />
      </MultiColumnWrapper>
    </AdminLayout>
  )
}

export const ChargerAdminPage = () => {
  const context = React.useContext(ColumnContext)

  const [chargePoints, setChargePoints] = useState([])
  const [selectedChargerID, setSelectedChargerID] = useState(null)
  const [loadingChargePoints, setLoadingChargePoints] = useState(true)

  useEffect(() => {
    if (selectedChargerID === null) {
      // Only load charge points on the initial load (before selected charge point ID is set)
      getChargePoints()
    }
  }, [selectedChargerID])

  function getChargePoints() {
    setLoadingChargePoints(true)
    fetchFromAPIBase({
      path: "/v1/charging/charge_points/?include_measurements=true",
    }).subscribe((response) => {
      if (response && !response.error) {
        sortBy(response, (r) => {
          const chargePoint = r as any
          return chargePoint.name
        })
        setChargePoints(response)
        setSelectedChargerID(response[0].identifer)
      }
    })
    setLoadingChargePoints(false)
  }

  return (
    <>
      {(context.numberColumns > 1 || selectedChargerID == null) && (
        <Column>
          <MultiColumnScrollSection>
            {loadingChargePoints || chargePoints.length === 0 ? (
              <Loading type="boxLoader" text="Loading chargers..." />
            ) : (
              chargePoints.map((chargePoint) => {
                return (
                  <ListTile
                    key={chargePoint.identifier}
                    selectable
                    selected={chargePoint.identifier == selectedChargerID}
                    onClick={() => {
                      setSelectedChargerID(chargePoint.identifier)
                    }}
                  >
                    <Stack spacing="XSmall">
                      <Heading type="title3">{chargePoint.name}</Heading>
                      <Text>{chargePoint.maximumPower}kW</Text>
                    </Stack>
                  </ListTile>
                )
              })
            )}
          </MultiColumnScrollSection>
        </Column>
      )}
      {(context.numberColumns > 1 || selectedChargerID != null) && (
        <Column>
          <MultiColumnScrollSection>
            <ChargerInner>
              {selectedChargerID ? (
                <ChargerAdmin selectedChargerID={selectedChargerID} />
              ) : (
                <Alert type="info">No charger selected</Alert>
              )}
            </ChargerInner>
          </MultiColumnScrollSection>
        </Column>
      )}
    </>
  )
}

export default Page
