// @ts-strict-ignore
import React from "react"

import Toggle from "react-toggle"
import "react-toggle/style.css"
import styled from "styled-components"
import TimeAgo from "timeago-react"

import {
  Loading,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  TextLink,
  Tooltip,
} from "@kiwicom/orbit-components"
import {
  Alert,
  CheckCircle,
  ChevronForward,
  Fuel,
  Notification,
  Reload,
} from "@kiwicom/orbit-components/icons"

import { chargingPortNameWithPosition } from "components/charging/utils"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"

import { formatAsShortDateTime, getDefaultTimezone } from "utils/date-utils"
import { fetchWithAuth } from "utils/fetch-utils"
import { groupBy } from "utils/struct-utils"

import {
  ChargeMetric,
  chargingSessionDuration,
  chargingSessionEnergyMetric,
  chargingSessionStart,
  chargingSessionStateOfChargeMetric,
  isEnded,
} from "../../charging/charge-reservation"
import {
  ChargePoint,
  ChargingSession,
  Reservation,
} from "../../charging/interfaces"
import { createUrlBuilder, urlBuilderType } from "../utils"
import { StartCharging } from "./start-charging"
import { StopCharging } from "./stop-charging"
import { Unlock } from "./unlock"

export interface ExtendedChargingSession extends ChargingSession {
  localConnectorId: number
  connectorId: number
  chargePointIdentifier: string
  chargePointId: string
  connectorReservationId: number
}

const StyledToggleDiv = styled.div`
  .custom-classname.react-toggle--checked .react-toggle-track {
    background-color: #00a991;
  }
  .custom-classname.react-toggle--unchecked .react-toggle-track {
    background-color: #970c0c;
  }
`

const ChargerAdminDetail = ({ icon, children }) => (
  <Stack direction="row" spacing="XSmall" shrink inline grow={false}>
    {icon}
    {children}
  </Stack>
)

const Stat = ({ title, children }) => (
  <Stack direction="row" inline>
    <Text weight="bold" as="span">
      {title}
    </Text>
    <Text>{children}</Text>
  </Stack>
)

function connectorAuthDetails(connector) {
  return (
    <ChargerAdminDetail
      icon={
        connector.authIsEnabled ? (
          <CheckCircle color="success" />
        ) : (
          <Alert color="critical" />
        )
      }
    >
      <Text>
        {connector.authIsEnabled
          ? "Reservation required to charge"
          : "Anyone can charge"}
      </Text>
    </ChargerAdminDetail>
  )
}

interface ChargingSessionsDisplayProps {
  chargingSessions?: ChargingSession[]
}

function ChargingSessionsDisplay(props: ChargingSessionsDisplayProps) {
  return (
    <EmberCardSection>
      <Stack direction="column" spacing="small">
        <Text type="secondary" size="small" weight="bold" uppercase>
          Charging Sessions
        </Text>
        <Table compact>
          <TableBody>
            {props.chargingSessions?.map((session: ExtendedChargingSession) => {
              return (
                <TableRow key={session.chargingSessionId}>
                  <TableCell>
                    <Text>
                      {session.vehicleChargingPort
                        ? chargingPortNameWithPosition(
                            session.vehicleChargingPort
                          )
                        : "Unknown"}
                    </Text>
                  </TableCell>
                  <TableCell>
                    {session.connectorReservationId ? null : (
                      <Tooltip
                        removeUnderlinedText
                        content="charging session had no reservation"
                      >
                        <Alert color="warning" />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>{chargingSessionStart(session)}</TableCell>
                  <TableCell>
                    <ChargeMetric
                      key="status"
                      icon={<Notification color="secondary" />}
                    >
                      {session.status}
                    </ChargeMetric>
                  </TableCell>
                  <TableCell>
                    {session.status !== "started"
                      ? chargingSessionDuration(session)
                      : null}
                  </TableCell>
                  <TableCell>{chargingSessionEnergyMetric(session)}</TableCell>
                  <TableCell>
                    {chargingSessionStateOfChargeMetric(session)}
                  </TableCell>
                  <TableCell>
                    {isEnded(session) ? null : (
                      <StopCharging
                        transactionID={session.chargingSessionId}
                        urlBuilder={createUrlBuilder(
                          session.chargePointIdentifier
                        )}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Stack>
    </EmberCardSection>
  )
}

interface UpcomingReservationsDisplayProps {
  reservations?: Reservation[]
  localConnectorId: number
}

function UpcomingReservationsDisplay(props: UpcomingReservationsDisplayProps) {
  const rows = []
  for (const reservation of props.reservations) {
    for (const reservedConnector of reservation.reservedConnectors) {
      if (
        props.localConnectorId === reservedConnector.connector.localConnectorId
      ) {
        rows.push(
          <TableRow key={reservation.reservationId}>
            <TableCell>
              <Text>{reservedConnector.maxPower}kW</Text>
            </TableCell>
            <TableCell>
              {formatAsShortDateTime(reservation.start, getDefaultTimezone())}
            </TableCell>
            <TableCell>
              {formatAsShortDateTime(reservation.end, getDefaultTimezone())}
            </TableCell>
            <TableCell>
              {reservation.vehicleChargingPorts
                .map(chargingPortNameWithPosition)
                .join(", ")}
            </TableCell>
            <TableCell>
              {reservation.fleets
                .map((fleet) => {
                  return fleet.name
                })
                .join(", ")}
            </TableCell>
            <TableCell>
              {reservation.parkingBays?.map((bay) => bay.name).join(",")}
            </TableCell>
            <TableCell>
              <TextLink
                href={`https://api.ember.to/admin/charging/reservedconnector/${reservedConnector.reservedConnectorId}/change`}
                external
              >
                <ChevronForward />
              </TextLink>
            </TableCell>
          </TableRow>
        )
      }
    }
  }
  return (
    <EmberCardSection>
      <Stack direction="column" spacing="small">
        <Text type="secondary" size="small" weight="bold" uppercase>
          Upcoming Reservations
        </Text>
        {rows.length !== 0 ? (
          <Table compact>
            <TableHead>
              <TableRow>
                <TableCell>Power</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell>Vehicles</TableCell>
                <TableCell>Fleets</TableCell>
                <TableCell>Parking Bays</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        ) : (
          <Text>No current or upcoming reservations</Text>
        )}
      </Stack>
    </EmberCardSection>
  )
}

interface ConnectorAdminProps {
  connectorID: number
  chargePoint: ChargePoint
  reservations: Reservation[]
  chargingSessions: ChargingSession[]
  urlBuilder: urlBuilderType
  updateChargePointHook: (identifier: string) => void
  isRefreshingChargingSessions: boolean
  isRefreshingReservations: boolean
}

/**
 * ConnectorAdmin contains administrative actions for a single charger cable.
 * For instance, changing the status of the cable or requesting updated information.
 *
 * @param props
 * @returns JSX for the connector admin
 */
export const ConnectorAdmin = ({
  connectorID,
  chargePoint,
  chargingSessions,
  reservations,
  urlBuilder,
  updateChargePointHook,
  isRefreshingChargingSessions,
  isRefreshingReservations,
}: ConnectorAdminProps) => {
  function getMeasurement(measurand: string) {
    const measurement = chargePoint.measurements[connectorID][measurand]
    if (measurement != null) {
      return measurement
    } else {
      return { value: null, updateTime: null }
    }
  }

  function getConnector() {
    return chargePoint.connectors.find((connector) => {
      return connector.localConnectorId === connectorID
    })
  }

  function getUpdateTime(metric: string): JSX.Element {
    return <TimeAgo live datetime={getMeasurement(metric).updateTime} />
  }

  function isChargingState(): boolean {
    return [
      "Charging",
      "Finishing",
      "Preparing",
      "SuspendedEV",
      "SuspendedEVSE",
    ].includes(getMeasurement("status").value)
  }

  function isUnavailable(): boolean {
    return getMeasurement("status").value === "Unavailable"
  }

  function buildToggle() {
    const isChecked = !isUnavailable()
    return (
      <StyledToggleDiv>
        <Toggle
          checked={isChecked}
          className="custom-classname"
          onChange={() => {
            fetchWithAuth(urlBuilder("change_availability"), "POST", {
              connector_id: connectorID,
              availability_type: isChecked ? "Inoperative" : "Operative",
            }).subscribe((response) => {
              if (response && !response.error) {
                updateChargePointHook(chargePoint.identifier)
              }
            })
          }}
        />
      </StyledToggleDiv>
    )
  }

  function updateStatus() {
    return (
      <TextLink
        onClick={() => {
          fetchWithAuth(urlBuilder("trigger_message"), "POST", {
            connector_id: connectorID,
            requested_message: "StatusNotification",
          }).subscribe((response) => {
            if (response && !response.error) {
              updateChargePointHook(chargePoint.identifier)
            }
          })
        }}
      >
        <Reload />
      </TextLink>
    )
  }

  function updateMeasurements() {
    return (
      <TextLink
        onClick={() => {
          fetchWithAuth(urlBuilder("trigger_message"), "POST", {
            connector_id: connectorID,
            requested_message: "MeterValues",
          }).subscribe((response) => {
            if (response && !response.error) {
              updateChargePointHook(chargePoint.identifier)
            }
          })
        }}
      >
        <Reload />
      </TextLink>
    )
  }

  function statusInfo() {
    if (!chargePoint) {
      return null
    }

    let icon = null
    switch (getMeasurement("status").value) {
      case "Available": {
        icon = buildToggle()
        break
      }
      case "Unavailable": {
        icon = buildToggle()
        break
      }
      case "Charging": {
        icon = <CheckCircle color="info" />
        break
      }
      case "Preparing": {
        icon = <CheckCircle color="info" />
        break
      }
      case "Finishing": {
        icon = <CheckCircle color="info" />
        break
      }
      default: {
        icon = <CheckCircle color="critical" />
        break
      }
    }

    let errorInfo = null
    if (getMeasurement("errorCode").value !== "NoError") {
      errorInfo = (
        <>
          <Stack direction="row" spacing="small" inline>
            <Text weight="bold">Vendor Error Code: </Text>
            <Text>{getMeasurement("vendorErrorCode").value}</Text>
          </Stack>
          <Stack direction="row" spacing="small" inline>
            <Text weight="bold">Error Code: </Text>
            <Text>{getMeasurement("errorCode")?.value}</Text>
          </Stack>
        </>
      )
    }

    return (
      <Stack direction="column" align="center" spacing="large" inline shrink>
        <ChargerAdminDetail icon={icon}>
          <Tooltip
            content={<>Updated: {getUpdateTime("status")}</>}
            removeUnderlinedText
          >
            <Text>{getMeasurement("status").value}</Text>
          </Tooltip>
          <Text>•</Text>
          <Tooltip
            content={<>Updated: {getUpdateTime("statusInfo")}</>}
            removeUnderlinedText
          >
            <Text>{getMeasurement("statusInfo").value}</Text>
          </Tooltip>
          <Text>{updateStatus()}</Text>
        </ChargerAdminDetail>
        {errorInfo}
      </Stack>
    )
  }

  function chargingStats() {
    return (
      <>
        <Tooltip
          content={<>Updated: {getUpdateTime("current.Import")}</>}
          removeUnderlinedText
        >
          <Stat title="Delivering:">
            {Math.round(getMeasurement("current.Import").value * 10) / 10} A
          </Stat>
        </Tooltip>
        <Tooltip
          content={<>Updated: {getUpdateTime("current.Offered")}</>}
          removeUnderlinedText
        >
          <Stat title="Offering:">
            {Math.round(getMeasurement("current.Offered").value * 10) / 10} A
          </Stat>
        </Tooltip>
        <Tooltip
          content={<>Updated: {getUpdateTime("power.Active.Import")}</>}
          removeUnderlinedText
        >
          <Stat title="Power:">
            {Math.round(getMeasurement("power.Active.Import").value / 100) / 10}{" "}
            kW
          </Stat>
        </Tooltip>
        <Tooltip
          content={<>Updated: {getUpdateTime("soC")}</>}
          removeUnderlinedText
        >
          <Stat title="State of Charge:">
            {Math.round(getMeasurement("soC").value * 10) / 10}%
          </Stat>
        </Tooltip>
      </>
    )
  }

  return (
    <Stack>
      <Stack direction="row" justify="between">
        <Text weight="bold" uppercase>
          Cable {connectorID}
        </Text>
        <Text spaceAfter="normal">
          {getConnector().maxAmperes}A {getConnector().standard} cable
        </Text>
      </Stack>
      <EmberCard>
        <EmberCardSection>
          <Stack direction="column">
            {statusInfo()}
            {connectorAuthDetails(getConnector())}
            <Stack direction="row" inline>
              <Text weight="bold">Measurements</Text>
              <Text>{updateMeasurements()}</Text>
            </Stack>
            {isChargingState() ? chargingStats() : null}
            <Tooltip
              content={
                <>
                  <Text>The meter reading on cable</Text>
                  <Text>
                    Updated: {getUpdateTime("energy.Active.Import.Register")}
                  </Text>
                </>
              }
              removeUnderlinedText
            >
              <ChargerAdminDetail icon={<Fuel color="primary" />}>
                <Text>
                  {Math.round(
                    getMeasurement("energy.Active.Import.Register").value / 100
                  ) / 10}
                  {" kWh"}
                </Text>
              </ChargerAdminDetail>
            </Tooltip>

            {!isChargingState() ? (
              <StartCharging
                connectorID={connectorID}
                urlBuilder={urlBuilder}
              />
            ) : null}

            <Unlock connectorID={connectorID} urlBuilder={urlBuilder} />
          </Stack>
        </EmberCardSection>

        {isRefreshingChargingSessions ? (
          <Loading
            type="inlineLoader"
            text="Loading charging sessions..."
          ></Loading>
        ) : (
          <ChargingSessionsDisplay
            chargingSessions={
              groupBy(chargingSessions, (session: ExtendedChargingSession) => {
                return session.localConnectorId
              })[connectorID]
            }
          />
        )}
        {isRefreshingReservations ? (
          <Loading type="inlineLoader" text="Loading reservations..."></Loading>
        ) : (
          <UpcomingReservationsDisplay
            reservations={reservations}
            localConnectorId={connectorID}
          />
        )}
      </EmberCard>
    </Stack>
  )
}
