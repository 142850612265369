// @ts-strict-ignore
import React, { useEffect, useRef, useState } from "react"

import { use100vh } from "react-div-100vh"
import styled, { css } from "styled-components"

import { Button, Grid, Stack, useMediaQuery } from "@kiwicom/orbit-components"
import { ChevronBackward } from "@kiwicom/orbit-components/icons"

import { DetailsInner, DetailsWrapper } from "./styled-components"

const ColumnContext = React.createContext(null)

interface MultiColumnLayoutProps {
  optionName: string // e.g. "trips" where the sidebar shows a list of trips
  detailSelected: boolean
  unselectDetail: () => void
  fullScreenDetail?: boolean
  sidebarWidth?: "normal" | "wide"
  showDetailsInScrollSection?: boolean
  sidebarHeader: React.ReactNode
  sidebarContent: React.ReactNode
  detailSection: React.ReactNode
}

const MultiColumnLayout = (props: MultiColumnLayoutProps) => {
  return (
    <MultiColumnWrapper sidebarWidth={props.sidebarWidth}>
      <MultiColumnLayoutInner {...props} />
    </MultiColumnWrapper>
  )
}

const MultiColumnLayoutInner = ({
  optionName,
  detailSelected,
  unselectDetail,
  fullScreenDetail = false,
  showDetailsInScrollSection = true,
  sidebarHeader,
  sidebarContent,
  detailSection,
}: MultiColumnLayoutProps) => {
  const context = React.useContext(ColumnContext)
  return (
    <>
      {(context.numberColumns > 1 || !detailSelected) && (
        <Column zIndex={2}>
          {sidebarHeader && (
            <StickyColumnHeader>{sidebarHeader}</StickyColumnHeader>
          )}
          <MultiColumnScrollSection>{sidebarContent}</MultiColumnScrollSection>
        </Column>
      )}
      {(context.numberColumns > 1 || detailSelected) && (
        <Column>
          {showDetailsInScrollSection == false ? (
            detailSection
          ) : (
            <MultiColumnScrollSection>
              <DetailsWrapper fullScreen={fullScreenDetail}>
                <DetailsInner fullScreen={fullScreenDetail}>
                  <Stack spacing="large">
                    {context.numberColumns === 1 && detailSelected && (
                      <Button
                        type="secondary"
                        iconLeft={<ChevronBackward />}
                        onClick={unselectDetail}
                      >
                        All {optionName}
                      </Button>
                    )}
                    {detailSection}
                  </Stack>
                </DetailsInner>
              </DetailsWrapper>
            </MultiColumnScrollSection>
          )}
        </Column>
      )}
    </>
  )
}

const StyledGrid = styled(Grid)`
  flex-grow: 1;
`

interface MultiColumnLayoutType {
  sidebarWidth?: "normal" | "wide"
  children: React.ReactNode
}

const MultiColumnWrapper = ({
  sidebarWidth = "normal",
  children,
}: MultiColumnLayoutType) => {
  const { isDesktop, isLargeDesktop } = useMediaQuery()
  const ref = useRef(null)
  const [topOffset, setTopOffset] = useState(0)

  // This works better than using 100vh in CSS because it fixes the issue in mobile
  // browsers where browser chrome appears and disappears depending on scroll position.
  const height = use100vh()

  useEffect(() => {
    setTopOffset(ref.current ? ref.current.offsetTop : 0)
  }, [ref.current])

  function numberColumns() {
    if (isDesktop || isLargeDesktop) {
      return 2
    } else {
      return 1
    }
  }

  let sidebarWidthPixels: string
  if (sidebarWidth == "normal") {
    sidebarWidthPixels = "400px"
  } else {
    sidebarWidthPixels = "500px"
  }

  return (
    <div ref={ref}>
      <StyledGrid
        columns={
          numberColumns() == 2
            ? `${sidebarWidthPixels} calc(100% - ${sidebarWidthPixels})`
            : "100%"
        }
        rows={`calc(${height}px - ${topOffset}px)`}
      >
        <ColumnContext.Provider value={{ numberColumns: numberColumns() }}>
          {children}
        </ColumnContext.Provider>
      </StyledGrid>
    </div>
  )
}

const StyledScrollSection = styled.div`
  overflow-y: auto;
  flex: 1;
`

const MultiColumnScrollSection = ({ children }) => (
  <StyledScrollSection>{children}</StyledScrollSection>
)

const Column = styled.div<{ zIndex?: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-left: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  :first-child {
    border-left: none;
  }
  ${(props) => (props.zIndex ? `z-index: ${props.zIndex};` : "")}
`

const PaddedSection = styled.div`
  padding: 15px ${(props) => props.theme.orbit.spaceMedium};
  margin: 0px !important;
  background: white;
`

const StickyColumnHeader = styled(PaddedSection)`
  position: sticky;
  top: 0px;
  z-index: 99;
  border-bottom: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  @media all and (display-mode: standalone) {
    position: unset;
  }
`

interface ListTileProps {
  children: React.ReactNode
  onClick?: ((event: React.MouseEvent<HTMLElement>) => void) | string
  onMouseOver?: (event: React.MouseEvent<HTMLElement>) => void
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void
  dataTest?: string
  selectable?: boolean
  selected?: boolean
}

const ListTile = ({
  children,
  onClick,
  onMouseOver,
  onMouseLeave,
  dataTest,
  selectable,
  selected,
}: ListTileProps) => {
  return (
    <ListTileHover
      href={typeof onClick == "string" ? onClick : null}
      onClick={typeof onClick != "string" ? onClick : null}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      data-test={dataTest}
      selected={selected}
      selectable={selectable}
    >
      <ListTileBorders selected={selected} selectable={selectable}>
        {children}
      </ListTileBorders>
    </ListTileHover>
  )
}

interface ListTileWrapperProps {
  selectable?: boolean
  selected?: boolean
}

const ListTileHover = styled.a<ListTileWrapperProps>`
  background: ${(props) => props.theme.orbit.backgroundCard};
  border-bottom: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  display: block;
  color: inherit;
  :hover {
    text-decoration: none;
  }
  ${(props) =>
    props.selectable &&
    css`
      cursor: pointer;
      :hover {
        background: ${(props) => props.theme.orbit.backgroundButtonWhiteHover};
      }
    `}
  ${(props) =>
    props.selected &&
    css`
      background: ${props.theme.orbit.backgroundBody};
    `}
`

const ListTileBorders = styled(PaddedSection)<ListTileWrapperProps>`
  border-left: 8px solid ${(props) => props.theme.orbit.backgroundCard};
  ${(props) =>
    props.selectable &&
    css`
      cursor: pointer;
      :hover {
        border-left-color: ${(props) => props.theme.orbit.paletteProductNormal};
        border-width: 0px 0px 0px 8px;
      }
    `}
  ${(props) =>
    props.selected &&
    css`
      border-left-color: ${props.theme.orbit.paletteProductNormal};
    `}
`

export {
  MultiColumnLayout,
  Column,
  ColumnContext,
  ListTile,
  MultiColumnWrapper,
  PaddedSection,
  MultiColumnScrollSection,
  StickyColumnHeader,
}
