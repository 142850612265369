// @ts-strict-ignore
import React, { FunctionComponent } from "react"

import {
  Alert as AlertIcon,
  CheckCircle,
} from "@kiwicom/orbit-components/icons"
import {
  Alert,
  Button,
  Loading,
  Popover,
  Stack,
  Text,
} from "@kiwicom/orbit-components/lib"

import { parseErrorMessage } from "api/errors"

import { fetchWithAuth } from "utils/fetch-utils"

import { urlBuilderType } from "../utils"

interface ExpectedResponse {
  wasSuccessful: boolean
  message: string
}

interface ChargerButtonProps {
  urlBuilder: urlBuilderType
  payload: Record<string, unknown>
  buttonText: string
  expectedResponses: {
    Accepted?: ExpectedResponse
    Rejected?: ExpectedResponse
    NotSupported?: ExpectedResponse
  }
  path: string
  componentType?: React.Component | FunctionComponent
  extraComponentProps?: Record<string, unknown>
  tooltip: string
  requestMethod?: "POST" | "GET" | "PUT"
}

interface ChargerButtonState {
  message?: JSX.Element
}

/**
 * ChargerButton encapsulates common logic for sending commands to the charge.
 * It also displays success or failure messages, which can be configured for different
 * expected responses from the charger.
 */
export class ChargerButton extends React.Component<
  ChargerButtonProps,
  ChargerButtonState
> {
  componentType: any // TODO: how to type the react components correctly?
  requestMethod: "POST" | "GET" | "PUT"

  constructor(props: ChargerButtonProps) {
    super(props)
    this.state = {
      message: null,
    }
    this.request = this.request.bind(this)
    this.componentType = this.props.componentType || Button
    this.requestMethod = this.props.requestMethod || "POST"
  }

  request() {
    this.setState({
      message: (
        <Loading
          type="searchLoader"
          text="Sending your request to the charger..."
        />
      ),
    })
    fetchWithAuth(
      this.props.urlBuilder(this.props.path),
      this.requestMethod,
      this.props.payload
    ).subscribe((response) => {
      if (response && !response.error) {
        const expectedResponse = this.props.expectedResponses[response] || null
        if (expectedResponse === null) {
          this.setState({
            message: (
              <Alert type="critical" icon={<AlertIcon />}>
                The charger responded to your request with: {response}
              </Alert>
            ),
          })
        } else {
          if (expectedResponse.wasSuccessful) {
            this.setState({
              message: (
                <Alert type="success" icon={<CheckCircle />}>
                  {expectedResponse.message}
                </Alert>
              ),
            })
          } else {
            this.setState({
              message: (
                <Alert type="critical" icon={<AlertIcon />}>
                  {expectedResponse.message}
                </Alert>
              ),
            })
          }
        }
      } else {
        this.setState({
          message: (
            <Alert type="critical" icon={<AlertIcon />}>
              An unexpected error occured: {parseErrorMessage(response)}
            </Alert>
          ),
        })
      }
    })
  }

  render(): JSX.Element {
    const Component = this.componentType

    if (!this.props.tooltip) {
      // No info text, so don't create a popover for this button
      return (
        <>
          <Component
            type="primary"
            size="normal"
            onClick={this.request}
            {...this.props.extraComponentProps}
          >
            {this.props.buttonText}
          </Component>
          {this.state.message && this.state.message}
        </>
      )
    }

    return (
      <Popover
        width="400px"
        onClose={() => {
          return this.setState({ message: null })
        }}
        content={
          <Stack direction="column">
            <Text>{this.props.tooltip}</Text>
            <Button onClick={this.request}>{this.props.buttonText}</Button>
            {this.state.message && this.state.message}
          </Stack>
        }
      >
        <Component
          type="primarySubtle"
          size="normal"
          {...this.props.extraComponentProps}
        >
          {this.props.buttonText}
        </Component>
      </Popover>
    )
  }
}
