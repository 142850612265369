// @ts-strict-ignore
import React from "react"

import TextLink from "@kiwicom/orbit-components/lib/TextLink"
import Map from "@kiwicom/orbit-components/lib/icons/Map"

import { ChargingSession, VehicleChargingPort } from "./interfaces"

export function linkToMap(latitude, longitude) {
  return (
    <TextLink
      href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
      external={true}
    >
      {" "}
      <Map />
    </TextLink>
  )
}

export function chargingSessionPower(chargingSession: ChargingSession): number {
  if (
    chargingSession.measurements.meterStart &&
    chargingSession.measurements.meterEnd
  ) {
    return (
      chargingSession.measurements.meterEnd.value -
      chargingSession.measurements.meterStart.value
    )
  } else {
    return null
  }
}

export function chargingPortNameWithPosition(
  vehicleChargingPort: VehicleChargingPort
): string {
  if (vehicleChargingPort.position) {
    return `${vehicleChargingPort.name} - ${vehicleChargingPort.position}`
  } else {
    return vehicleChargingPort.name
  }
}
