const apiBaseUrl = process.env.GATSBY_API_BASE_URL

function buildURL(chargePointID: string, endpoint: string): string {
  return `${apiBaseUrl}/beta/charge/charge_points/${chargePointID}/${endpoint}`
}

export interface urlBuilderType {
  (endpoint: string): string
}

/**
 * createURLBuilder will curry a function, enclosing which charger subsequent
 * requests to the urlBuilderType will be made for.
 *
 * For example,
 *
 * let urlBuilder = createUrlBuilder("foo")
 *
 * a call to urlBuilder('bar') will return `${apiBaseUrl}/beta/charge/charge_points/foo/bar`
 *
 *
 * @param chargePointID
 * @returns {urlBuilderType}
 */
export function createUrlBuilder(chargePointID: string): urlBuilderType {
  return function (endpoint: string) {
    return buildURL(chargePointID, endpoint)
  }
}
